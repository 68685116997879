#gameDetails {
  overflow: scroll;
}

/* .viewGamePagination {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
} */

/* .MuiDataGrid-root .MuiDataGrid-footer {
  display: none !important;
} */

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-colCell {
  border-right: 1px solid white !important;
}

.MuiDataGrid-root .MuiDataGrid-row {
  margin-top: 4px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container > div {
  width: 100px;
  margin: 10px;
}

#gameImageGrid {
  border: 1px solid grey;
  padding: 20px;
  background: white;
}

#colorGrid {
  height: 30px;
  background: #e91e63;
  width: 100%;
}

#outer {
  padding: 20px;
  background: grey;
}

#borderGrid {
  border: 1px solid grey;
}
