#path-breadcrum {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 0px 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

#viewGameSteps .MuiBreadcrumbs-separator {
    margin: 0 !important;
}